import React from "react";
import "./styles.css";
import WithProviders from "../../../components/WithProviders";

import PersonalisationImage from "../../images/Personalisation.png";
import PersonalisationImage2 from "../../images/Personalisation@2x.png";
import PersonalisationImage3 from "../../images/Personalisation@3x.png";
import { Message } from "../../../lib/getMessage";
import RecommendationImage from "../../images/recommendation.png";
import RecommendationImage2 from "../../images/recommendation@2x.png";
import RecommendationImage3 from "../../images/recommendation@3x.png";

import SegmentImage from "../../images/segment-image.png";
import SegmentImage2 from "../../images/segment-image@2x.png";
import SegmentImage3 from "../../images/segment-image@3x.png";

import BottomSegment from "../../images/bottom-segment.png";
import BottomSegment2 from "../../images/bottom-segment@2x.png";
import BottomSegment3 from "../../images/bottom-segment@3x.png";

import Layout from "../../../components/layout";
import Header from "../../../components/header";
import HeroBanner from "../../../components/HeroBanner";
import ProductWithImage from "../../../components/ProductWithImage";

import ListView from "../../../components/ListView";

const personalisationSectionData = (
  <ListView
    lists={[
      <Message dictKey="products.marketing.personalisation.item1" />,
      <Message dictKey="products.marketing.personalisation.item2" />,
    ]}
  />
);

function PersonalisationAndMarketing(props) {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={props.location}
    >
      <div>
        <Header
          current="product"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="personalisation-page">
          <HeroBanner
            title={<Message dictKey="products.marketing.title" />}
            className={`personalisation-banner ${
              isDirectionRTL && "personalisation-banner-rtl"
            }`}
            isDirectionRTL={isDirectionRTL}
          />
          <div className="personalisation-section">
            <ProductWithImage
              name="segment-personalisation"
              className="segment-personalisation"
              heading={
                <Message dictKey="products.marketing.personalisation.heading" />
              }
              description={[
                {
                  type: "p",
                  val: personalisationSectionData,
                  id: 1,
                },
              ]}
              image={{
                normal: PersonalisationImage,
                xx: PersonalisationImage2,
                xxx: PersonalisationImage3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </div>
          <div className="recommendation-section">
            <ProductWithImage
              name="segment-personalisation"
              className="segment-personalisation"
              heading={
                <Message dictkye="products.marketing.recommendation.heading" />
              }
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.marketing.recommendation.description" />
                  ),
                  id: 1,
                },
              ]}
              image={{
                normal: RecommendationImage,
                xx: RecommendationImage2,
                xxx: RecommendationImage3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </div>
          <div className="segment-section">
            <ProductWithImage
              name="segment-personalisation"
              className="segment-personalisation"
              heading={
                <Message dictKey="products.marketing.promotion.heading" />
              }
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.marketing.promotion.description" />
                  ),
                  id: 2,
                },
              ]}
              image={{
                normal: SegmentImage,
                xx: SegmentImage2,
                xxx: SegmentImage3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </div>
          <div className="bottom-segment">
            <ProductWithImage
              name="segment-personalisation"
              className="segment-personalisation"
              heading={<Message dictKey="products.marketing.loyalty.heading" />}
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.marketing.loyalty.description" />
                  ),
                  id: 2,
                },
              ]}
              image={{
                normal: BottomSegment,
                xx: BottomSegment2,
                xxx: BottomSegment3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default WithProviders(PersonalisationAndMarketing);
